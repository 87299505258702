import { App } from '@vue/runtime-core';

export default class RollbarOperator {
	public static handleVueErrors(app: App): void {
		app.config.errorHandler = (error): void => {
			RollbarOperator.triggerError(error as Error);
			throw error;
		};
	}

	public static triggerError(message: Error | string): void {
		console.error(message);

		if (!window.Rollbar) {
			return;
		}

		window.Rollbar.error(message);
	}
}
