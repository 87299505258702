import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "ACTDashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Introduction = _resolveComponent("Introduction")!
  const _component_Teaser = _resolveComponent("Teaser")!
  const _component_LeadsBlock = _resolveComponent("LeadsBlock")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Introduction),
    _createVNode(_component_Teaser),
    _createVNode(_component_LeadsBlock),
    (_ctx.standAloneLogin)
      ? (_openBlock(), _createBlock(_component_LogOut, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}