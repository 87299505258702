import { defineComponent } from 'vue';
import LeadsBlockComponent from '@/presentation/components/leads-block/leads-block-class';

export default defineComponent({
	setup: () => {
		const component = new LeadsBlockComponent();

		return {
			trackClickBeforeRedirect:
				component.trackClickBeforeRedirect.bind(component)
		};
	}
});
