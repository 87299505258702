class TableauInitialiser {
	public loadTableauScript(): void {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = webpackDefinitions.TABLEAU_EMBEDDING_URL;
		document.body.appendChild(script);
	}
}

export default new TableauInitialiser();
