import { computed } from 'vue';
import { IAnchorClickEvent } from '@/models/link.interface';
import DataTracker from '@/infrastructure/data-tracker';
import store from '@/store';

export default class LeadsBlockComponent {
	private linkClickTracked = false;

	private tracker = computed((): DataTracker => {
		return store.getters.tracker();
	});

	public trackClickBeforeRedirect(event: IAnchorClickEvent): void {
		if (this.linkClickTracked) {
			this.toggleLinkClickTrackedFlag();
			return;
		}

		event.preventDefault();

		this.tracker.value.trackLeadsClick();

		// Toggle flag and re-trigger click, so that the original link is opened.
		this.toggleLinkClickTrackedFlag();
		event.target?.click();
	}

	private toggleLinkClickTrackedFlag(): void {
		this.linkClickTracked = !this.linkClickTracked;
	}
}
