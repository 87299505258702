import state from './state';
import { IGetters } from '@/models/store/state.interface';

const getters: IGetters = {
	standAloneLogin: () => {
		return state.standAloneLogin;
	},
	tracker: () => {
		return state.tracker;
	}
};

export default getters;
