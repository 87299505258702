import { Tracker, Product, Action } from '@studyportals/datalake-event-tracker';
import RollbarOperator from '@/infrastructure/rollbar-operator';

export default class DataTracker {
	private tracker: Tracker;

	private get userId(): string {
		const userElement: HTMLDivElement | null =
			document.querySelector('.js-enrolmentTool');
		return userElement?.dataset.id || '';
	}

	constructor() {
		this.tracker = new Tracker(Product.ACT_DASHBOARD);
	}

	public trackLeadsClick(): void {
		this.track(Action.CLICK, 'leads_button', this.userId);
	}

	private track(action: Action, label: string, property: string): void {
		try {
			this.tracker.trackStructuredEvent(
				{
					action,
					label,
					property
				},
				'ACTDashboard',
				{
					enableSnowplow: true,
					enableGTM: true
				}
			);
		} catch (e) {
			RollbarOperator.triggerError(e as Error);
			console.error(`Issue encountered while tracking ACT Dashboard event.`, e);
		}
	}
}
