import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WhiteBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "ACTDashboardContentWrapper" }, [
      _createElementVNode("div", null, [
        _createElementVNode("h2", { class: "ACTDashboardTitle" }, "Don't guess, ACT on insights"),
        _createElementVNode("p", { class: "ACTDashboardText" }, " The ACT team can elevate your institution's international marketing and student recruitment strategy with expertise, tools, and knowledge, helping you excel in higher education's competitive landscape. ")
      ]),
      _createElementVNode("img", {
        class: "ACTDashboardImage",
        src: "//monet-prtl-co.imgix.net/Endor/ACT/act-data-visualisation.svg",
        alt: "ACT Data Visualisation"
      })
    ], -1 /* HOISTED */)
  ])))
}