import { reactive } from 'vue';

import { IState } from '@/models/store/state.interface';
import DataTracker from '@/infrastructure/data-tracker';

const state: any = reactive({
	standAloneLogin: false,
	tracker: new DataTracker()
});

export default state as IState;
