import state from './state';
import { IMutations } from '@/models/store/mutations.interface';

const mutations: IMutations = {
	setStandAloneLogin: (standAloneLogin) => {
		state.standAloneLogin = standAloneLogin;
	}
};

export default mutations;
