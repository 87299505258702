import { defineComponent } from 'vue';

export default defineComponent({
	setup: () => {
		const urlParts = [
			'https://public.tableau.com/views/StudyportalsUNESCOGlobalStudentMobility-Freebie/',
			'GlobalStudentMobility?:language=en-US&:display_count=n&:origin=viz_share_link&:toolbar=no'
		];
		return {
			tableauHTML: `<tableau-viz id="tableauViz" src="${urlParts.join('')}" hide-tabs />`
		};
	}
});
