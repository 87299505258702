import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "WhiteBlock" }
const _hoisted_2 = { class: "ACTDashboardContentWrapper" }
const _hoisted_3 = { class: "ACTDashboardTextWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "ACTDashboardTitle" }, " Empower Your Institution with Data-Driven Decisions! ", -1 /* HOISTED */)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "ACTDashboardText" }, " It's not just data; it's a story waiting to be told. Our dashboards translate millions of data points into narratives about student interests, regional diversity, emerging trends, and more. Shape your institution's future by leveraging real-time student demand data. ", -1 /* HOISTED */)),
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.trackClickBeforeRedirect && _ctx.trackClickBeforeRedirect(...args))),
          class: "ChampionButton ACTDashboardButton",
          href: "https://studyportals.typeform.com/to/LrQccAJb",
          target: "_act_leads"
        }, " Get in touch ")
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        class: "ACTDashboardImage ACTDashboardLeadsImage",
        src: "//monet-prtl-co.imgix.net/Endor/ACT/act-leads-visualisation.svg",
        alt: "Empowerment of data-driven decisions"
      }, null, -1 /* HOISTED */))
    ])
  ]))
}