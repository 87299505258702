import { Component, defineComponent } from 'vue';
import AppComponent from '@/presentation/app-class';
import Introduction from '@/presentation/components/introduction/introduction.vue';
import Teaser from '@/presentation/components/teaser/teaser.vue';
import LeadsBlock from '@/presentation/components/leads-block/leads-block.vue';
import LogOut from '@/stand-alone/log-out/log-out.vue';

export default defineComponent({
	components: {
		Introduction: Introduction as Component,
		Teaser: Teaser as Component,
		LeadsBlock: LeadsBlock as Component,
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new AppComponent();

		return {
			standAloneLogin: component.standAloneLogin
		};
	}
});
