import { createApp, h } from 'vue';
import store from '@/store';
import App from '@/presentation/app.vue';
import LogIn from '@/stand-alone/log-in/log-in.vue';
import Bootstrapper from '@/bootstrapper';
import StylesheetLoader from '@/stylesheet-loader';
import RollbarOperator from '@/infrastructure/rollbar-operator';
import TableauInitialiser from '@/infrastructure/tableau-initialiser';

const standAloneLogin = webpackDefinitions.STAND_ALONE_LOGIN === 'true';
const url = webpackDefinitions.VUE_APP_ICONSET_URL;

declare global {
	interface Window {
		hj: (action: string, name: string) => void;
		Rollbar?: {
			error: (message: Error | string) => void;
			warn: (message: Error | string) => void;
		};
	}
}

StylesheetLoader.loadStylesheet(url);

store.mutations.setStandAloneLogin(standAloneLogin);

const placeholderSelector = '#ACTDashboardPlaceholder';

Bootstrapper.bootstrap()
	.then(() => {
		const app = createApp({
			render: () => {
				return h(App);
			}
		});

		RollbarOperator.handleVueErrors(app);

		app.mount(placeholderSelector);

		TableauInitialiser.loadTableauScript();
	})
	.catch((error: Error) => {
		if (!standAloneLogin || error.name !== 'NotAuthorizedException') {
			throw error;
		}

		const app = createApp({
			render: () => {
				return h(LogIn);
			}
		});

		app.mount(placeholderSelector);
	});
